.login-main-cont{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
}

.sign-up-cont{
    width: 55%;
    background-color: #E5E5E5;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.details-card-cont{
    height: 80%;
    width: 60%;
    background-color: #ffffff;
    border-top-left-radius: 5%;
    border-top-right-radius: 5%;
}

.sign-logo-cont{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; 
    height: 25%;
    width: 20%;
}

.social-buttons{
    display: flex;
    flex-direction: row;
    justify-content: center;
   
}

.adds-cont{
    width: 45%;
    height: 100%;
    background-color: #4424A7;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
}

.topEllipse-cont{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.messageBox-cont{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bottomEllipse-cont{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 10%;
    
}

.input-cont{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 10%;
    margin-right: 2%;
}

.pass-cont{
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    margin-right: 20px;
    text-decoration: underline;
    margin-top: 1%;
    margin-bottom: 6%;
    cursor: pointer;
}


.span-or{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 16px;
    text-align: center;
    color: #E5E7EB;
    margin-top: 2%; 
}

.btn-1{
    width: 40%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 8px;
    border-width: 0px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
}

.foot-para{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #868B90;
}

.top-header-cont{
    display: none;
}

@media only screen and (max-width: 800px) {
    .adds-cont{
        display: none;
    }

    .details-card-cont{
        height: 90vh;
        width: 100%;
        background-color: #ffffff;
        border-top-left-radius: 0%;
        border-top-right-radius: 0%;
    }

    .sign-logo-cont{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; 
        background-color: #4424A7;
        height: 35%;
        width: 100%;
        border-radius: 0%;
        margin-top: 0%;
        margin-bottom: 0%;
    }

    .sign-up-cont{
        width: 100%;
        background-color: #E5E5E5;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    }

    .top-header-cont{
        display: block;
    }

    .top-logo-cont{
        display: none;
    }
  }

