.home-main-cont{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    background-color: #E5E5E5;
}

.home-cont{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    background-color: #2E3B55;
    height:10%;
    
}

.logout-btn{
    height: 20px;
    width: 20px;
    margin-left: 2px;
}

.logout-cont{
    height: 40px;
    width: 40px;
    background-color: #FFFFFF;
    border-radius: 30px;
    border-width: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 2%;
    margin-right: 1%;
    cursor: pointer;
}

.header-text{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    color: #868B90; 
    margin-left: 5%;
    display: flex;
    flex-direction: row;
    /* //width: 100%; */
}

.side-bar{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
   
}

.side-bar-btn-cont{
    height: 100%;
    width: 20%;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    
}

.side-btn{
    height: 40px;
    width: 100%;
    background-color: black;
    /* background-color: #4424A7; */
    /* opacity: 0.3; */
    border-width: 1px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    color: #ffffff; 
    border-color: grey;
    cursor: pointer;
    margin-top: 5%;
}

.mod-btn{
    height: 40px;
    width: 100%;
    background-color: #4424A7;
    border-width: 1px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    color: #ffffff; 
    border-color: grey;
    cursor: pointer;
    margin-top: 5%;
}

.home-content-cont{
    width: 100%;
    background-color: #E5E5E5;
   
}



