.forgot-card-cont{
    height: 45%;
    width: 60%;
    background-color: #ffffff;
    border-top-left-radius: 5%;
    border-top-right-radius: 5%;
}

.reset-button{
    height: 50px;
    width: 80%;
    background: #4424A7;
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    border-width: 0px;
    cursor: pointer;
    margin-right: 1%;
    margin-top: 3%;
}

.arrow-img-cont{
    height: 30%;
    width: 40%;
    margin-bottom: 10%;
    margin-right: 15%;

}

.forgot-content-cont{
    display: none;
}

@media only screen and (max-width: 800px) {
    .forgot-card-cont{
        height: 80%;
        width: 100%;
        background-color: #ffffff;
        border-top-left-radius: 0%;
        border-top-right-radius: 0%;
    }

    .arrow-img-cont{
       display: none;
    }

    .image{
        width: 250px;
    }

    .forgot-content-cont{
        display: block;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .content-class{      
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        font-weight: 200;
        line-height: 19px;
        text-align: center;
        color: #19212C;
        padding: 2%;
        padding-top: 0%;
    }

    .head-class{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 40px;
        text-align: center;
        color: #19212C;
    }

    .footer{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #868B90;
        text-decoration: underline;
    }
}

