.details-main-cont{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card-detail-cont{
    background-color: #7bc2e0;
    margin: 10px;
    padding: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: #1e98ba;
    border-radius: 10px;
    width: 500px;
}

.detail-back-btn{
    height: 40px;
    width: 100px;
    background-color: antiquewhite;
    color: black;
    border-radius: 5px;
    border-width: 0px;
    font-family: "inter";
    font-size: 16px;
    font-weight: 300;
    cursor: pointer;
}